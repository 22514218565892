import {
    CSSProperties,
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Form,
    Input,
    Button,
    Table,
    Modal,
    Popconfirm,
    message,
    Row,
    Col,
} from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import dayjs from "dayjs";
import { request } from "../../utils/request";
import { IPuzzle } from "../../typings";
import { isChinease, randomCharactors } from "../../utils";

const validateMessages = {
    required: "${label}不能为空",
};
const createColumns = (
    handleDelete: (key: string) => void,
    edit: (record: IPuzzle) => void
) => {
    return [
        {
            title: "序号",
            dataIndex: "order",
            key: "order",
            width: 64,
            render: (text: number) => <span>{text + 1}</span>,
        },
        {
            title: "成语",
            dataIndex: "truth",
            key: "truth",
            width: 168,
        },
        {
            title: "问题",
            dataIndex: "questions",
            key: "questions",
            width: 168,
            render: (text: string[]) => {
                return text
                    .map((t: string) => {
                        return t !== "" ? t : "   __   ";
                    })
                    .join("");
            },
        },
        {
            title: "九宫格",
            dataIndex: "nine",
            key: "nine",
            width: 180,
            render: (text: string[], record: IPuzzle) => (
                <Row gutter={[2, 2]} justify="space-around" align="middle">
                    {(text || []).map((v: string, index: number) => {
                        return (
                            <Col key={index} className="gutter-row" span={8}>
                                <div
                                    style={{
                                        background:
                                            (record.truth || "").indexOf(v) ===
                                            -1
                                                ? "#d9d9d9"
                                                : "#ffacac",
                                        textAlign: "center",
                                        padding: 5,
                                    }}
                                >
                                    {v}
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            ),
        },
        {
            title: "释义",
            dataIndex: "puzzle",
            key: "puzzle",
        },

        {
            title: "查看数",
            dataIndex: "viewed",
            key: "viewed",
            width: 81,
        },
        {
            title: "正确",
            dataIndex: "right",
            key: "right",
            width: 81,
        },
        {
            title: "错误",
            dataIndex: "wrong",
            key: "wrong",
            width: 81,
        },
        {
            title: "提示",
            dataIndex: "cue",
            key: "cue",
            width: 81,
        },
        {
            title: "求助",
            dataIndex: "help",
            key: "help",
            width: 81,
        },
        {
            title: "分享",
            dataIndex: "share",
            key: "share",
            width: 81,
        },
        {
            title: "更新于",
            align: "center",
            dataIndex: "updated_at",
            key: "updated_at",
            width: 190,
            render: (text: Date) => (
                <span>{dayjs(text).format("YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            title: "操作",
            key: "action",
            align: "center",
            width: 32,
            render: (_: any, record: IPuzzle) => (
                <>
                    <Button
                        type="primary"
                        style={{ marginBottom: 8 }}
                        onClick={() => edit(record)}
                    >
                        编辑
                    </Button>

                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <Button
                            style={{ background: "red", borderColor: "red" }}
                            type="primary"
                        >
                            删除
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ];
};

const type = "DraggableBodyRow";

interface IRow {
    index: number;
    moveRow: any;
    className: string;
    style: CSSProperties;
}

const DraggableBodyRow: FC<IRow> = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
}): ReactElement => {
    const ref = useRef<HTMLTableRowElement>(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName:
                    dragIndex < index
                        ? " drop-over-downward"
                        : " drop-over-upward",
            };
        },
        drop: (item: any) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{ cursor: "move", ...style }}
            {...restProps}
        />
    );
};

const Puzzles: FC = (): ReactElement => {
    const [visible, setVisible] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    const [nine, setNine] = useState<string[]>([]);

    const [missIndex, setMissIndex] = useState<number[]>([]);

    const [questions, setQuestions] = useState<string[]>([]);

    const [form] = Form.useForm();

    const [data, setData] = useState<IPuzzle[]>([]);

    const [newMode, setNewMode] = useState<boolean>(true);
    const [currentPuzzle, setCurrentPuzzle] = useState<IPuzzle | null>(null);

    useEffect(() => {
        request
            .get("puzzle/puzzles?type=idiom", localStorage.getItem("token"))
            .then((res: any) => {
                setData(res.data || []);
            });
    }, []);

    const onTruthChange = (truth: string): void => {
        setMissIndex([]);
        updateAnswer([]);

        let truthArr = truth.split("");
        setQuestions(truthArr);
    };

    const updateAnswer = (indexs: number[]) => {
        const words = (form.getFieldValue("truth") || "")
            .split("")
            .filter((_t: string, i: number) => {
                return indexs.includes(i);
            })
            .join("");

        setNine(randomCharactors(words));
    };

    const createAudio = (id: string, text: string) => {
        return new Promise<void>((resolve) => {
            request
                .post("puzzle/tts", {
                    id,
                    text,
                    force: true,
                })
                .then((res) => {
                    resolve();
                })
                .catch((err) => {
                    resolve();
                });
        });
    };

    const onFinish = (values: any) => {
        setConfirmLoading(true);

        if (newMode) {
            request
                .put(
                    "puzzle/puzzle",
                    {
                        questions: questions,
                        truth: values.truth.trim(),
                        nine: nine,
                        puzzle: values.puzzle.trim(),
                        order: data.length,
                        type: "idiom",
                    },
                    localStorage.getItem("token")
                )
                .then((res: any) => {
                    setConfirmLoading(false);
                    form.resetFields();
                    setData([
                        ...(data || []),
                        {
                            ...res.data,
                        },
                    ]);

                    message.success(res.msg);
                    setVisible(false);
                })
                .catch((error: any) => {
                    message.error(error.msg);
                });
        } else {
            const update = {
                questions: questions,
                truth: values.truth.trim(),
                nine: nine,
                puzzle: values.puzzle.trim(),
            };
            request
                .post(
                    `puzzle/puzzle/${currentPuzzle!.key}`,
                    update,
                    localStorage.getItem("token")
                )
                .then(async (res: any) => {
                    await createAudio(
                        currentPuzzle!.key,
                        `${values.truth.trim()}:${values.puzzle.trim()}`
                    );

                    setConfirmLoading(false);
                    setData(
                        data.map((d: IPuzzle) => {
                            if (d.key === res.data.key) {
                                return JSON.parse(JSON.stringify(res.data));
                            } else {
                                return d;
                            }
                        })
                    );

                    form.resetFields();

                    message.success(res.msg);
                    setVisible(false);
                })
                .catch((error: any) => {
                    message.error(error.msg);
                });
        }
    };

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleDelete = (key: string): void => {
        request
            .delete(
                "puzzle/puzzles?type=idiom",
                [key],
                localStorage.getItem("token")
            )
            .then((res: any) => {
                setData(res.data);
                message.success(res.msg);
            });
    };

    const editPuzzle = (puzzle: IPuzzle): void => {
        setNewMode(false);

        setCurrentPuzzle(puzzle);
        setQuestions(puzzle.questions);
        setNine(puzzle.nine || []);
        form.setFieldsValue({
            nine: puzzle.nine,
            truth: puzzle.truth,
            puzzle: puzzle.puzzle,
        });
        setVisible(true);
    };

    const updateOrder = (key: string, order: number) => {
        const update = {
            order: order,
        };

        return new Promise<IPuzzle>((resolve, reject): void => {
            request
                .post(
                    `puzzle/puzzle/${key}`,
                    update,
                    localStorage.getItem("token")
                )
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    const moveRow = useCallback(
        async (dragIndex: number, hoverIndex: number) => {
            const dragRow = data[dragIndex];
            let updatedData = update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });

            for (let d = 0; d < updatedData.length; d++) {
                if (d !== updatedData[d].order) {
                    updatedData[d] = await updateOrder(updatedData[d].key, d);
                }
            }
            setData(updatedData);
        },
        [data]
    );

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const columns: any = createColumns(handleDelete, editPuzzle);
    return (
        <>
            <div
                style={{
                    height: "50px",
                    lineHeight: "50px",
                    float: "right",
                    marginRight: "8px",
                }}
            >
                <Button
                    type="primary"
                    onClick={() => {
                        form.setFieldsValue({
                            truth: "",
                            puzzle: "",
                        });
                        setNewMode(true);
                        setNine([]);
                        setQuestions([]);
                        setMissIndex([]);
                        setVisible(true);
                    }}
                >
                    添加新成语
                </Button>
            </div>

            <DndProvider backend={HTML5Backend}>
                <Table
                    style={{ padding: "0 8px" }}
                    columns={columns}
                    dataSource={data}
                    components={components}
                    onRow={(record, index) => {
                        return {
                            index,
                            moveRow,
                        } as any;
                    }}
                />
            </DndProvider>

            <Modal
                title={newMode ? "添加新成语" : "更新成语"}
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    validateMessages={validateMessages}
                >
                    <Form.Item
                        name="truth"
                        label="成语"
                        rules={[{ required: true }]}
                    >
                        <Input
                            onChange={(e) => {
                                onTruthChange(e.target.value);
                            }}
                        />
                    </Form.Item>

                    <Form.Item name="questions" label="问题">
                        <Row
                            style={{ padding: "0 2px 0 10px" }}
                            gutter={[8, 8]}
                            justify="space-around"
                            align="middle"
                        >
                            {questions.map((v: string, index: number) => {
                                return (
                                    <Col
                                        key={index}
                                        className="gutter-row"
                                        span={v && !isChinease(v) ? 0 : 6}
                                    >
                                        <Input
                                            allowClear
                                            disabled={!isChinease(v)}
                                            value={v}
                                            onChange={(e) => {
                                                let tmp = [...questions];
                                                tmp[index] = e.target.value;

                                                const newMiss = [
                                                    ...missIndex,
                                                    index,
                                                ];

                                                updateAnswer(newMiss);

                                                setMissIndex(newMiss);

                                                setQuestions(tmp);
                                            }}
                                        />
                                    </Col>
                                );
                            })}

                            <Col key={99} className="gutter-row" span={24}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        onTruthChange(
                                            form.getFieldValue("truth")
                                        );
                                    }}
                                >
                                    重置
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        name="puzzle"
                        label="释义"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea
                            showCount
                            autoSize={{ minRows: 5, maxRows: 10 }}
                        />
                    </Form.Item>
                    <div style={{ padding: "0 100px 0 153px" }}>
                        <Row
                            gutter={[8, 8]}
                            justify="space-around"
                            align="middle"
                        >
                            {nine.map((v: string, index: number) => {
                                return (
                                    <Col
                                        key={index}
                                        className="gutter-row"
                                        span={8}
                                    >
                                        <div
                                            style={{
                                                background:
                                                    (
                                                        form.getFieldValue(
                                                            "truth"
                                                        ) || ""
                                                    ).indexOf(v) === -1
                                                        ? "#d9d9d9"
                                                        : "#ffacac",
                                                textAlign: "center",
                                                padding: 20,
                                            }}
                                        >
                                            {v}
                                        </div>
                                    </Col>
                                );
                            })}

                            <Button
                                type="primary"
                                onClick={() => {
                                    updateAnswer(missIndex);
                                }}
                            >
                                刷新随机
                            </Button>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default Puzzles;
