import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';

const Error = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉, 页面不存在"
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate('/signin');
          }}
        >
          登陆
        </Button>
      }
    />
  );
};

export default Error;
