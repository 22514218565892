import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Error from "./pages/Error";
import { Routes, Route } from "react-router-dom";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="*" element={<Error />} />
            </Routes>
        </>
    );
}

export default App;
