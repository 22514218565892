import React from "react";
import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";
function Home() {
    return (
        <Result
            style={{ paddingTop: "256px", color: "#fff" }}
            icon={<SmileOutlined />}
            title="你的指尖, 有改变世界的力量"
        />
    );
}

export default Home;
