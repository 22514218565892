// const baseUrl = 'http://localhost:3018';
// const baseUrl = 'https://mini.iweniwen.com';

const baseUrl =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3018"
        : "https://mini.iweniwen.com";

const requestUrl = `${baseUrl}/api`;

const f = (
    url: string,
    method: string,
    params?: any,
    token?: string | null
) => {
    let option: any = {
        method: method,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    };

    if (token) {
        option.headers.Authorization = token;
    }

    if (params) {
        option.body = JSON.stringify(params);
    }

    return fetch(`${requestUrl}/${url}`, option)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
};

const request = {
    get: (url: string, token?: string | null) => {
        return new Promise((resolve, reject) => {
            f(url, "GET", null, token).then((res: any) => {
                if (res.code === -1) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    },
    post: (url: string, params: any, token?: string | null) => {
        return new Promise((resolve, reject) => {
            f(url, "POST", params, token).then((res: any) => {
                if (res.code === -1) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    },
    put: (url: string, params: any, token?: string | null) => {
        return new Promise((resolve, reject) => {
            f(url, "PUT", params, token).then((res: any) => {
                if (res.code === -1) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    },
    delete: (url: string, params: any, token?: string | null) => {
        return new Promise((resolve, reject) => {
            f(url, "DELETE", params, token).then((res: any) => {
                if (res.code === -1) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    },
};

export { request, baseUrl };
