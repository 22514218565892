import { useEffect } from "react";
import { Form, Input, Button, Checkbox, Row, Col, message } from "antd";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/request";

// const signinUrl = "http://localhost:3018/api/signin";
const signinUrl = `${baseUrl}/api/signin`;

const SignIn = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    useEffect(() => {
        if (localStorage.getItem("token")) {
            navigate("/dashboard");
        }

        const remember = localStorage.getItem("remember");
        if (remember === "YES") {
            form.setFieldsValue({
                username: localStorage.getItem("username") || "",
                password: "",
                remember: remember === "YES" ? true : false,
            });
        }
    });

    const onFinish = (values: any) => {
        localStorage.setItem("remember", values.remember ? "YES" : "NO");
        localStorage.setItem(
            "username",
            values.remember ? values.username : ""
        );

        fetch(signinUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (data.code === -1) {
                    localStorage.setItem("token", data.data);
                    message.success(data.msg);
                    navigate("/dashboard");
                } else {
                    localStorage.removeItem("token");
                    message.error(data.msg);
                }
            });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Row>
            <Col
                style={{ marginTop: "128px" }}
                className="gutter-row"
                span={4}
                offset={10}
            >
                <Form
                    form={form}
                    name="basic"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[{ required: true, message: "请输入用户名" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: "请输入密码" }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{ offset: 5, span: 19 }}
                    >
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
                        <Button type="primary" htmlType="submit">
                            登陆
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default SignIn;
