import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
const Maths = () => {
  const navigate = useNavigate();
  return (
    <div style={{ margin: 8 }}>
      <Row gutter={[8, 8]} justify="space-around" align="middle">
        <Col
          span={8}
          onClick={() => {
            navigate('/dashboard/base-math');
          }}
        >
          <div className="math-box">数数</div>
        </Col>
        <Col span={8}>
          <div className="math-box">加减法</div>
        </Col>
        <Col span={8}>
          <div className="math-box">九九乘法</div>
        </Col>
        <Col span={8}>
          <div className="math-box">比较</div>
        </Col>
        <Col span={8}>
          <div className="math-box">认识图形</div>
        </Col>
        <Col span={8}>
          <div className="math-box">认识钟表</div>
        </Col>
      </Row>
    </div>
  );
};

export default Maths;
