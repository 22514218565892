import charactors from './charactors';

const _ = require('lodash');

const randomCharactors = (truth: string): string[] => {
  let chars: string[] = [];

  for (let c of truth) {
    const r = c.trim();
    if (r) {
      chars.push(r);
    }
  }

  while (chars.length < 9) {
    const randomIndex = Math.floor(Math.random() * charactors.length);
    const char: string = charactors[randomIndex] as string;
    if (
      char &&
      chars.findIndex((c: string) => {
        return c === char;
      }) === -1
    ) {
      chars.push(char);
    }
  }

  return _.shuffle(chars);
};

const isChinease = (s: string): boolean => {
  const chineseRegex = /[\u4e00-\u9fa5]+/g;
  const result = s.match(chineseRegex);
  return result ? true : false;
};

export { randomCharactors, isChinease };
