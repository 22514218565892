import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Row, Col, Tabs, Button, Modal, message, Breadcrumb } from 'antd';
import BaseMathItem from '../../components/BaseMathItem';
import { IBaseMathItem } from '../../typings';
import { request } from '../../utils/request';

const { TabPane } = Tabs;

const Maths = () => {
  const [newMode, setNewMode] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const [items, setItems] = useState<IBaseMathItem[]>([]);

  const [newItem, setNewItem] = useState<IBaseMathItem>();
  const [tabKey, setTabKey] = useState<string>('1-5');

  const [itemMap, setItemMap] = useState<{ [key: string]: IBaseMathItem[] }>({
    '1-5': [],
  });

  useEffect(() => {
    request
      .get('math/maths', localStorage.getItem('token'))
      .then((res: any) => {
        setItems(res.data || []);
      });
  }, [tabKey]);

  useEffect(() => {
    setItemMap(groupBy(items, 'math_type'));
  }, [items]);

  const updateItem = (d: { [key: string]: number | string }): void => {
    setNewItem(JSON.parse(JSON.stringify(Object.assign(newItem, d))));
  };

  const addNewItem = () => {
    setNewMode(true);

    const valueRange = tabKey.split('-');
    const min = parseInt(valueRange[0]);
    const max = parseInt(valueRange[1]);

    setNewItem({
      order: items.length + 1,
      math_type: tabKey,
      image:
        'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      num1: min,
      num2: max,
      answer: min,
      viewed: 0,
    });
    setVisible(true);
  };

  const handleEdit = (d: IBaseMathItem) => {
    setNewMode(false);
    setNewItem(d);
    setVisible(true);
  };

  const handleDelete = (id: string) => {
    request
      .delete('math/maths', [id], localStorage.getItem('token'))
      .then((res: any) => {
        setItems(res.data);
        message.success(res.msg);
      });
  };

  const handleOk = () => {
    if (!newItem) {
      return;
    }
    setConfirmLoading(true);
    if ([newItem.num1, newItem.num2].indexOf(newItem.answer) === -1) {
      message.error('请设置答案');
      setConfirmLoading(false);
      return;
    }

    if (newItem.num1 === newItem.num2) {
      message.error('数值不能相等');
      setConfirmLoading(false);
      return;
    }

    const valueRange = tabKey.split('-');
    const min = parseInt(valueRange[0]);
    const max = parseInt(valueRange[1]);

    if (
      newItem.num1 > max ||
      newItem.num1 < min ||
      newItem.num2 > max ||
      newItem.num2 < min
    ) {
      message.error(`数值设置只能在: ${min} - ${max} 之间`);
      setConfirmLoading(false);
      return;
    }

    const n = JSON.parse(JSON.stringify(newItem));
    if (newMode) {
      newItem.order = (itemMap[tabKey] || []).length + 1;
      request
        .put('math/math', newItem, localStorage.getItem('token'))
        .then((res: any) => {
          setConfirmLoading(false);
          setItems([...items, n]);
          message.success(res.msg);
          setVisible(false);
        })
        .catch((error: any) => {
          message.error(error.msg);
        });
    } else {
      const update = {
        image: newItem.image,
        num1: newItem.num1,
        num2: newItem.num2,
        answer: newItem.answer,
      };
      request
        .post(`math/math/${newItem.id}`, update, localStorage.getItem('token'))
        .then((res: any) => {
          setConfirmLoading(false);
          setItems(
            items.map((d: IBaseMathItem) => {
              if (d.id === res.data.id) {
                return JSON.parse(JSON.stringify(res.data));
              } else {
                return d;
              }
            }),
          );
          message.success(res.msg);
          setVisible(false);
        })
        .catch((error: any) => {
          message.error(error.msg);
        });
    }

    setConfirmLoading(false);
    setVisible(false);
  };

  const operations = (
    <Button
      type="primary"
      onClick={() => {
        addNewItem();
      }}
    >
      添加新题
    </Button>
  );

  return (
    <>
      <div style={{ margin: 8 }}>
        <div className="mb-8">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="./#/dashboard/math">数学</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>数数</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Tabs
          defaultActiveKey={tabKey}
          type="card"
          tabBarExtraContent={operations}
          onTabClick={(key: string) => {
            setTabKey(key);
          }}
        >
          <TabPane tab="1-5" key="1-5"></TabPane>
          <TabPane tab="6-10" key="6-10"></TabPane>
          <TabPane tab="11-15" key="11-15"></TabPane>
          <TabPane tab="16-20" key="16-20"></TabPane>
        </Tabs>

        <Row gutter={[8, 8]}>
          {(itemMap[tabKey] || []).map((i: IBaseMathItem, index: number) => {
            return (
              <Col span={4} key={index}>
                <BaseMathItem
                  item={i}
                  showEdit={true}
                  updateItem={updateItem}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  disabled={true}
                />
              </Col>
            );
          })}
        </Row>

        <Modal
          title={newMode ? '添加' : '更新'}
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={() => {
            setVisible(false);
          }}
        >
          <BaseMathItem item={newItem!} updateItem={updateItem} />
        </Modal>
      </div>
    </>
  );
};

export default Maths;
