import { useEffect } from 'react';
import Puzzles from './puzzles';
import Idioms from './idioms';

import Maths from './maths';

import BaseMath from './maths/BaseMath';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import { OrderedListOutlined } from '@ant-design/icons';
const { Header, Content, Sider } = Layout;
function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathnames = location.pathname.split('/');

  useEffect(() => {
    const remember = localStorage.getItem('token');
    if (!remember) {
      navigate('/signin');
    }
  });

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#fff',
            }}
          >
            <h3
              className="title cursor"
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              小程序
            </h3>

            <div>
              <span
                className="cursor"
                onClick={() => {
                  localStorage.removeItem('token');
                  navigate('/signin');
                }}
              >
                退出
              </span>
            </div>
          </div>
        </Header>

        <Layout>
          <Sider>
            <Menu
              theme="dark"
              defaultSelectedKeys={[pathnames[pathnames.length - 1]]}
              mode="inline"
            >
              <Menu.Item
                key="puzzles"
                icon={<OrderedListOutlined />}
                onClick={() => {
                  navigate('/dashboard/puzzles');
                }}
              >
                儿童益智谜语
              </Menu.Item>

              <Menu.Item
                key="idioms"
                icon={<OrderedListOutlined />}
                onClick={() => {
                  navigate('/dashboard/idioms');
                }}
              >
                成语
              </Menu.Item>

              <Menu.Item
                key="math"
                icon={<OrderedListOutlined />}
                onClick={() => {
                  navigate('/dashboard/math');
                }}
              >
                学数学
              </Menu.Item>
            </Menu>
          </Sider>
          <Content>
            <Routes>
              <Route path="/puzzles" element={<Puzzles />} />
              <Route path="/idioms" element={<Idioms />} />

              <Route path="/math" element={<Maths />} />
              <Route path="/base-math" element={<BaseMath />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default Dashboard;
