import React, { FC, useState } from 'react';
import {
  InputNumber,
  Checkbox,
  Button,
  Popconfirm,
  Dropdown,
  Menu,
  Image,
} from 'antd';
import { IBaseMathItem } from '../typings';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';

interface IProps {
  item: IBaseMathItem;
  updateItem: (d: { [key: string]: number | string }) => void;
  handleDelete?: (id: string) => void;
  handleEdit?: (d: IBaseMathItem) => void;
  showEdit?: boolean;
  disabled?: boolean;
}
const images = [
  'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
];

const getBaseName = (s: string) => {
  const splits = s.split('/');
  return splits[splits.length - 1];
};

const BaseMathItem: FC<IProps> = ({
  item,
  updateItem,
  handleDelete,
  handleEdit,
  showEdit,
  disabled,
}) => {
  const menu = (
    <Menu
      onClick={(option) => {
        console.log(images[parseInt(option.key)]);

        updateItem({
          image: images[parseInt(option.key)],
        });
      }}
    >
      {images.map((img: string, i: number) => {
        return (
          <Menu.Item
            key={i}
            icon={<Image preview={false} width={36} src={img} />}
          >
            <span className="ml-5">{getBaseName(img)}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Dropdown disabled={disabled} overlay={menu} trigger={['contextMenu']}>
          <img style={{ width: '100%' }} alt="" src={item.image} />
        </Dropdown>
        {showEdit ? (
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: 3,
              right: 3,
            }}
          >
            <div
              className="mr-3"
              style={{ background: '#666', padding: '1px 8px', color: '#fff' }}
            >
              {item.viewed}
            </div>
            <Button
              size="small"
              className="mr-3"
              type="primary"
              onClick={() => {
                if (handleEdit) {
                  handleEdit(item);
                }
              }}
              icon={<FormOutlined />}
            />

            <Popconfirm
              title="确定要删除吗?"
              onConfirm={() => {
                if (handleDelete) {
                  handleDelete(item.id!);
                }
              }}
            >
              <Button
                size="small"
                type="primary"
                danger
                onClick={() => {}}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="space-around mt-8">
        <div className="mr-8">
          <InputNumber
            disabled={disabled}
            addonBefore={
              <Checkbox
                disabled={disabled}
                checked={item.num1 === item.answer}
                onChange={(e) => {
                  updateItem({
                    answer: e.target.checked ? item.num1 : -1,
                  });
                }}
              />
            }
            min={0}
            value={item.num1}
            onChange={(value: number) => {
              updateItem({
                num1:
                  item.num2 === value
                    ? value + (value > item.num1 ? 1 : -1)
                    : value,
              });
            }}
          />
        </div>
        <div>
          <InputNumber
            disabled={disabled}
            addonBefore={
              <Checkbox
                disabled={disabled}
                checked={item.num2 === item.answer}
                onChange={(e) => {
                  updateItem({
                    answer: e.target.checked ? item.num2 : -1,
                  });
                }}
              />
            }
            min={0}
            value={item.num2}
            onChange={(value: number) => {
              updateItem({
                num2:
                  item.num1 === value
                    ? value + (value > item.num2 ? 1 : -1)
                    : value,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BaseMathItem;
